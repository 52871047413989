// 3.14 Volunteer
.volunteerUser {
  &__box {
    display: inline-block;
    text-align: center;
  }
  &__thumb {
    border-radius: 50%;
    overflow: hidden;
  }
  &__profile {
    display: block;
    margin-top: -88px;
    position: relative;
    ul {
      background: $colorWhite;
      border: $border;
      box-shadow: $box-shadow;
      border-radius: 134px;
      padding: 30px;
      display: inline-flex;
      margin-left: -11px;
      position: relative;
      padding-right: 41px;
      li {
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid $colorWhite;
        margin-right: -11px;
        position: relative;
        &:nth-child(1) {
          z-index: 6;
        }
        &:nth-child(2) {
          z-index: 5;
        }
        &:nth-child(3) {
          z-index: 4;
        }
        &:nth-child(4) {
          z-index: 3;
        }
        &:nth-child(5) {
          z-index: 2;
        }
        &:nth-child(6) {
          z-index: 1;
        }
        &:last-child {
          &::after,
          &::before {
            position: absolute;
          }
          &::before {
            border-radius: 50%;
            background: $colorBlack;
            content: "";
            width: 100%;
            height: 100%;
            opacity: 0.6;
          }
          &::after {
            content: "200+";
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }
  }
}

.volunteerBlock {
  $self: &;
  &__name {
    @include font-size(20px);
    font-weight: 500;
    margin-bottom: 15px;
  }
  &__figure {
    margin-bottom: 31px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    background-color: $colorWhite;
    &__thumb {
      border-radius: 50%;
      filter: grayscale(1);
      @include transitionSmooth;
    }
  }
  &:hover {
    #{$self}__figure {
      &__thumb {
        @include transitionSmooth;
        transform: scale(1.2);
        filter: grayscale(0);
      }
    }
  }
}
