/*
1.1 Variables
*/

// Fonts
$fontPrimary: "Oswald", sans-serif;
$fontSecondary: "Barlow", sans-serif;

// Colors
$colorPrimary: #eb9309;
$colorSecondary: #7fb432;

$colorWhite: #fff;
$colorBlack: #000;
$colorBlack2: #191b1e;
$colorBlack3: #001b36;
$colorBlackGray: #303030;

$colorTitle: #0d0d0d;

$colorMeta: #a1a1a1;
$colorText: #666666;

$grayColor0: #60646b;
$grayColor1: #a8a8a8;
$grayColor2: #303030;
$grayColor3: #484848;
$grayColor4: #f7f7f7;
$grayColor5: #f1f1f1;
$grayColor6: #f2f2f2;
$grayColor7: #f8f7f7;
$grayColor8: #dddddd;
$grayColor9: #f3f3f3;
$grayColor10: #d2d2d2;
$grayColor11: #fdfdfd;
$grayColor12: #a2a2a2;
$grayColor13: #dcdcdc;
$grayColor14: #e6e6e6;
$grayColor15: #f4f4f4;
$grayColor16: #dadada;
$grayColor17: #e4e4e4;
$grayColor18: #f6f3ee;
$grayColor19: #acacac;
$grayColor20: #a0a0a0;
$grayColor21: #eeeeee;
$grayColor22: #8b8f9e;

$colorDarkFour: #768197;
$colorDarkFive: #8892a5;

$colorRating: #f7931e;

$colorTagsOne: #f67575;
$colorTagsTwo: #f07654;
$colorTagsThree: #97c94c;
$colorTagsFour: #c41dff;
$colorTagsFive: #ffad33;

$specialColor: #ff2200;

$colorShade: #ffefec;

$box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);

$border: 1px solid $grayColor5;
